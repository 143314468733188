import { useCallback, useEffect, useState } from 'react';

import { PartnerConfig } from '@/configs/partner';
import { Account, Order } from '@/types';

import { fetchSessionData } from '../utils/fetchSessionData';

interface SessionDataProps {
  email: string;
  emailToken: string | null;
  partner: string;
  partnerConfig: PartnerConfig;
  enabled: boolean;
}

interface SessionDataResponse {
  data?: {
    order?: Order;
    account?: Account;
  };
  isLoading: boolean;
  isError: boolean;
}

const useSessionData = ({
  email,
  emailToken,
  partner,
  partnerConfig,
  enabled,
}: SessionDataProps): SessionDataResponse => {
  const [data, setData] = useState<SessionDataResponse['data']>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchSessionDataCallback = useCallback(async () => {
    if (!enabled) return;

    setIsLoading(true);
    setIsError(false);

    await fetchSessionData(email, emailToken, partner, partnerConfig)
      .then((resp) => {
        if (resp.error) setIsError(true);
        if (resp.data) setData(resp.data);
      })
      .finally(() => setIsLoading(false));
  }, [email, emailToken, enabled, partner, partnerConfig]);

  useEffect(() => {
    fetchSessionDataCallback();
  }, [fetchSessionDataCallback]);

  return { data, isLoading, isError };
};

export default useSessionData;
