'use client';

import { HelpEmailLink } from '@/app/components/ui';
import { HelpEmailLinkProps } from '@/app/components/ui/HelpEmailLink/HelpEmailLink';
import { config } from '@/configs/partner';

const EssenceHelpLink = ({
  standalone,
  className,
  color,
}: HelpEmailLinkProps): JSX.Element => {
  const helpEmail = config['essence'].supportEmail;
  return (
    <HelpEmailLink
      standalone={standalone}
      helpEmail={helpEmail}
      className={className}
      color={color}
    />
  );
};

export default EssenceHelpLink;
