import { getCookie } from '@jouzen/ecom-utils';
import { env } from 'next-runtime-env';

import { decodeTokenToEmail } from '@/utils/decodeToken';

export const getEmailFromSession = () => {
  const isNotProd = env('NEXT_PUBLIC_OURA_ENV') !== 'production';
  const fakeSession = getCookie('fake_session');
  const session = getCookie('session');

  if (fakeSession && isNotProd) {
    return fakeSession;
  }
  if (session) {
    return decodeTokenToEmail(session);
  } else return null;
};
