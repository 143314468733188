'use client';
import { createContext, ReactNode, useContext, useMemo } from 'react';

import { usePathname } from '@/i18n/routing';
import { getEmailFromSession } from '@/utils/getSession';

import useSession from '../hooks/useSession';

type SessionContext = any;

const Context = createContext<SessionContext | null>(null);

export default function SessionProvider({
  children,
  partner,
}: {
  readonly children: ReactNode;
  readonly partner: string;
}) {
  const email = getEmailFromSession();
  const pathname = usePathname();

  const { isLoading, isError } = useSession({
    partner,
    email: email!,
    pathname,
  });

  const value = useMemo(
    () => ({ isError: isError, isLoading }),
    [isError, isLoading],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export const useSessionContext = (): SessionContext => {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('Did you forget to include SessionProvider in your tree?');
  }
  return ctx;
};
