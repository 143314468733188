import Content404 from './Content404';
import EmailLayout, { type EmailLayoutProps } from './EmailLayout';
import GlobalHeader from './GlobalHeader';
import LoadingScreen from './LoadingScreen';
import PageLayout, { type PageLayoutProps } from './PageLayout';
import ProductLayout from './ProductLayout';
import Section, { type SectionProps } from './Section';
import StepLayout from './StepLayout';
import SummaryLayout from './SummaryLayout';

export type { EmailLayoutProps, PageLayoutProps, SectionProps };

export {
  Content404,
  EmailLayout,
  GlobalHeader,
  LoadingScreen,
  PageLayout,
  ProductLayout,
  Section,
  StepLayout,
  SummaryLayout,
};
