'use client';
import { useEffect } from 'react';

import { config } from '@/configs/partner';
import { useRouter } from '@/i18n/routing';
import { accountAdded, emailAdded } from '@/lib/account/accountSlice';
import { useAppDispatch } from '@/lib/hooks';
import { orderAdded } from '@/lib/order/orderSlice';
import { Account, Order } from '@/types';

import { getEmailToken } from '../utils/emailTokenHelper';
import useSessionData from './useSessionData';

interface Props {
  partner: string;
  email: string;
  pathname: string;
}

const useSession = ({ partner, email, pathname }: Props) => {
  const dispatch = useAppDispatch();
  const { push } = useRouter();
  const partnerConfig = config[partner];
  const emailToken = getEmailToken();

  // Check whether or not to fetch & hydrate data
  const shouldVerifySession = !partnerConfig.publicRoutes.includes(pathname);
  const enabled =
    !!email &&
    shouldVerifySession &&
    !!partnerConfig.fetchDataRoutes?.includes(pathname);

  // Fetch data
  const { data, isLoading, isError } = useSessionData({
    email,
    emailToken,
    partner,
    partnerConfig,
    enabled,
  });

  // Hydrate state & route user
  useEffect(() => {
    if (!data || !enabled) return;

    if (email) dispatch(emailAdded(email));
    if (orderStateIsHydrated(data.order)) dispatch(orderAdded(data.order!));
    if (accountStateIsHydrated(data.account))
      dispatch(accountAdded(data.account!));

    const targetRoute = data.order?.orderComplete
      ? partnerConfig.completedStep
      : partnerConfig.successStep;
    push(targetRoute);
  }, [email, data, partnerConfig, dispatch, push, enabled]);

  useEffect(() => {
    if (isError) push(partnerConfig.invalidStep);
  }, [isError, partnerConfig, push]);

  return { data, isLoading, isError };
};

export default useSession;

const orderStateIsHydrated = (order?: Order) =>
  Boolean(order?.lineItems?.length);
const accountStateIsHydrated = (account?: Account) =>
  Boolean(account?.orders?.length);
