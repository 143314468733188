'use client';

import { EmailLayout } from '@/app/components/layout';

import { EssenceHelpLink } from '../components';

const Content = (): JSX.Element => {
  return (
    <EmailLayout
      title="This Page is Temporarily Unavailable"
      description="This page is currently under maintenance. Please check back soon."
      help={<EssenceHelpLink standalone />}
      imageSrc="/eop/woman-long-hair.jpg"
      partner="essence"
      isInvalid={false}
      isMaintenance={true}
    />
  );
};

export default Content;
