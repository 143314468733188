'use client';

import { useFeatureGate } from '@statsig/react-bindings';
import { PropsWithChildren, ReactNode } from 'react';

import SessionProvider from '@/app/contexts/SessionProvider';
import { useAnalytics } from '@/app/hooks';

import MaintenancePage from './maintenance/page';

export default function Layout({ children }: PropsWithChildren): ReactNode {
  const { value: isPartnerGateEnabled } = useFeatureGate('essence');

  useAnalytics();

  if (isPartnerGateEnabled) {
    return <SessionProvider partner="essence">{children}</SessionProvider>;
  }

  return <MaintenancePage />;
}
